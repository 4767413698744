import {
  buildHasuraQuery,
  ConfigurationType as MuiAdminConfigurationType,
  DefaultConfiguration as MuiFormDefaultConfiguration,
  HasuraQuery,
} from '@kirz/mui-admin';

import { api } from 'services/api';

export const Configuration = () => ({
  muiAdmin: {
    ...MuiFormDefaultConfiguration,
    defaultPhoneCountry: 'BE',
    defaultLocale: 'en',
    availableLocales: ['en'],
    hasura: {
      ...MuiFormDefaultConfiguration.hasura,
      async request(query, options) {
        const { extractResult, ...data } = buildHasuraQuery(query);

        const { data: response } = await api.hasuraClientInstance.post(
          '',
          data,
          {
            headers: {
              ...(options?.showRemoved && {
                'X-Hasura-Removed-Entities': 'true',
              }),
            },
          },
        );

        return extractResult ? extractResult(response.data) : response.data;
      },
      subscribe(request: HasuraQuery, onNext: (value: any) => void) {
        const { extractResult, query, variables } = buildHasuraQuery(request);

        const onNextResponse = ({ data }: any) => {
          onNext(
            typeof extractResult === 'function' ? extractResult(data) : data,
          );
        };

        const unsubscribe = api.hasuraWsClientInstance!.subscribe(
          {
            query,
            variables,
          },
          {
            next: onNextResponse,
            error: () => {},
            complete: () => {},
          },
        );

        return unsubscribe;
      },
      primaryKey: 'id',
    },
    rest: {
      ...MuiFormDefaultConfiguration.rest,
      client: api.instance,
    },
  } as MuiAdminConfigurationType,
});
