export const FeedbackTypes = [
  { value: 'negative', text: 'Negative' },
  { value: 'neutral', text: 'Neutral' },
  { value: 'positive', text: 'Positive' },
];

export const TextPositions = [
  { value: 'top-left', text: 'Top, Left' },
  { value: 'top-center', text: 'Top, Center' },
  { value: 'top-right', text: 'Top, Right' },
  { value: 'center-left', text: 'Center, Left' },
  { value: 'center-center', text: 'Center, Center' },
  { value: 'center-right', text: 'Center, Right' },
  { value: 'bottom-left', text: 'Bottom, Left' },
  { value: 'bottom-center', text: 'Bottom, Center' },
  { value: 'bottom-right', text: 'Bottom, Right' },
];

export const TextAlignments = [
  { value: 'left', text: 'Left' },
  { value: 'center', text: 'Center' },
  { value: 'right', text: 'Right' },
];

export const FontFamilies = [
  { value: 'BebasNeue', text: 'Bebas Neue' },
  { value: 'BricolageGrotesque24pt', text: 'Bricolage Groteque' },
  { value: 'Cantarell', text: 'Cantarell' },
  { value: 'LibreFranklin', text: 'Libre Franklin' },
  { value: 'MerriweatherSans', text: 'Merriweather Sans' },
  { value: 'Montserrat', text: 'Montserrat' },
  { value: 'Oswald', text: 'Oswald' },
  { value: 'PTSans', text: 'PT Sans' },
  { value: 'Roboto', text: 'Roboto' },
  { value: 'Salsa', text: 'Salsa' },
  { value: 'UbuntuMono', text: 'Ubuntu Mono' },
];
