import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FileInput,
  CheckboxInput,
  AutocompleteInput,
  LanguagesContext,
  TranslationInput,
} from '@kirz/mui-admin';
import { Box, Chip } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { secondsToDuration } from 'utils/secondsToDuration';

export const AudiosTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { defaultLanguageId } = useContext(LanguagesContext);
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'imageId',
            headerName: 'Image',
            type: 'file',
            hideText: true,
            width: 80,
            sortable: false,
          },
          {
            field: 'bigImageId',
            headerName: 'Big image',
            type: 'file',
            sortable: false,
            hideText: true,
            width: 80,
          },
          {
            headerName: 'Name',
            field: 'name',
            flex: 1,
            minWidth: 100,
            type: 'translation',
          },
          // {
          //   headerName: 'Color',
          //   field: 'color',
          //   type: 'color',
          // },
          {
            headerName: 'Variants',
            field: 'variants',
            width: 150,
            selector:
              'variants (orderBy: {duration: ASC}) { id duration isPremium fileId }',
            renderCell({ value }) {
              if (!value?.length) {
                return (
                  <Box
                    sx={{
                      opacity: 0.4,
                      minHeight: 52,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    No data
                  </Box>
                );
              }

              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    minHeight: 52,
                    py: 0.5,
                    mt: -0.5,
                    alignItems: 'center',
                    //
                  }}
                >
                  {value?.map((x: any) => (
                    <Chip
                      key={x.id}
                      label={`${x.duration}m`}
                      variant="outlined"
                      color={!x.isPremium ? 'primary' : 'secondary'}
                      size="small"
                      sx={{ mr: 0.5, cursor: 'pointer', mt: 0.5 }}
                      component="a"
                      href={`/api/v1/files/${x.fileId}`}
                      target="_blank"
                    />
                  ))}
                </Box>
              );
            },
          },
          {
            headerName: 'Category',
            field: 'category { *name* }',
            type: 'relationship',
            valueGetter({ value, row }) {
              return row.category?.name?.[defaultLanguageId];
            },
          },
          {
            headerName: 'Music',
            field: 'musicAfterMeditation { *name* }',
            type: 'relationship',
            valueGetter({ value, row }) {
              return row.musicAfterMeditation?.name?.[defaultLanguageId];
            },
          },
          {
            headerName: 'Popularity',
            field: 'popularity',
            valueFormatter: ({ value }) => `${value}%`,
          },
          {
            headerName: 'Played duration',
            field: 'duration',
            valueFormatter: ({ value }) => secondsToDuration(value),
          },
          {
            field: 'isDownloadable',
            headerName: 'Download',
            type: 'boolean',
            sortable: false,
          },
          {
            field: 'isActive',
            headerName: 'Active',
            type: 'boolean',
            sortable: false,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="audio"
        columns={columns}
        editPageUrl="/meditations"
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        getRowHeight={() => 'auto'}
        formTitle={() => 'New meditation'}
        formDialogProps={{
          ...rest.formDialogProps,
          autoFocus: false,
          formProps: {
            ...rest.formDialogProps?.formProps,
            defaultValues: {
              ...rest.formDialogProps?.formProps?.defaultValues,
              isDownloadable: true,
              isActive: true,
            },
          },
        }}
        searchFilter={{
          ...rest.searchFilter,
          inputProps: {
            placeholder: 'Search by name',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { defaultName: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'Category',
              field: 'categoryId',
              source: 'category',
              selection: 'id name',
              itemText: 'name',
              itemValue: 'id',
            },
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'Music after meditation',
              field: 'afterMeditationMusicId',
              source: 'music',
              selection: 'id name',
              itemText: 'name',
              itemValue: 'id',
            },
            {
              type: 'boolean',
              name: 'Premium',
              field: 'isPremium',
            },
            {
              type: 'boolean',
              name: 'Download',
              field: 'isDownloadable',
            },
            {
              type: 'boolean',
              name: 'New',
              field: 'isNew',
            },
            {
              type: 'boolean',
              name: 'Active',
              field: 'isActive',
            },
          ],
        }}
      >
        <TranslationInput
          name="name"
          label="Name"
          required
          gridCols={{ md: 6, lg: 6, xl: 6 }}
        />
        <AutocompleteInput
          name="categoryId"
          source="category"
          mode="hasura"
          selection="id name"
          label="Category"
          itemText={(row) => row.name[defaultLanguageId]}
          itemValue="id"
          required
          disabled={
            !!rest.formDialogProps?.formProps?.defaultValues?.categoryId
          }
        />
        <AutocompleteInput
          name="afterMeditationMusicId"
          source="music"
          mode="hasura"
          selection="id name"
          label="Music after meditation"
          itemText="name"
          itemValue="id"
        />
        <CheckboxInput name="isDownloadable" label="Download" sm="auto" />
        <CheckboxInput name="isActive" label="Active" sm="auto" />
        <FileInput
          name="imageId"
          label="Image"
          required
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />
      </DataTableEx>
    );
  },
);
