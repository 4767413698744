import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  AutocompleteInput,
  Grid,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

export function Notifications() {
  const [sendTarget, setSendTarget] = useState<'specific' | 'all'>('specific');
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'title', headerName: 'Title', width: 200 },
      { field: 'text', headerName: 'Text', flex: 1 },
      {
        field: 'users',
        headerName: 'Users',
        flex: 1,
        valueGetter({ value }) {
          if (value === null) {
            return 'All';
          }

          return value.map((x: any) => x.email ?? x.fullName).join(',');
        },
        sortable: false,
      },
      { field: 'createdAt', headerName: 'Created at', type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Notifications"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Send new
        </Button>
      }
    >
      <DataTableEx
        id="notifications-table"
        ref={tableRef}
        source="notification"
        columns={columns}
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        editable={false}
        formTitle={() => 'New Push Notification'}
        formDialogProps={{
          autoFocus: false,
          maxWidth: 'sm',
          formSubmitterProps: {
            mode: 'rest',
            url: '/one-signal/notification',
          },
        }}
      >
        <Grid md={12}>
          <FormControl>
            <FormLabel>Recipients</FormLabel>
            <RadioGroup
              row
              value={sendTarget}
              onChange={(e) => {
                setSendTarget(e.target.value as any);
              }}
            >
              <FormControlLabel
                value="specific"
                control={<Radio />}
                label="Send to specific users"
              />
              <FormControlLabel
                value="all"
                control={<Radio />}
                label="Send to all"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {sendTarget === 'specific' && (
          <AutocompleteInput
            name="users"
            multiple
            label="Users"
            required
            mode="hasura"
            source="user"
            selection="id email"
            itemText="email"
            itemValue="id"
            filter={{
              oneSignalUserId: { _isNull: false },
            }}
          />
        )}
        <FormInput name="title" label="Title" required />
        <FormInput name="text" label="Text" required multiline rows={3} />
      </DataTableEx>
    </TablePageLayout>
  );
}
