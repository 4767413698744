export type LoginPayload = {
  email: string;
  identityId?: string | number;
  validationCode?: string;
  password?: string;
};

export enum LoginError {
  IDENTITY_NOT_FOUND = 1,
  WRONG_VALIDATION_CODE,
  IDENTITY_SELECTION_REQUIRED,
  PASSWORD_VALIDATION_REQUIRED,
  WRONG_PASSWORD,
}

export type LoginResponse = {
  errorCode?: LoginError;
  errorData?: any;
  success?: boolean;
};

export type UserIdentity = {
  name: string;
  userId: number;
  role: string;
};
