import { PaletteOptions } from '@mui/material/styles';

import { colors } from './colors';

export const palette: PaletteOptions = {
  mode: 'light',
  primary: {
    main: '#5048E5',
    light: '#828DF8',
    dark: '#3832A0',
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: '#10B981',
    light: '#3FC79A',
    dark: '#0B815A',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#14B8A6',
    light: '#43C6B7',
    dark: '#0E8074',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#FFB020',
    light: '#FFBF4C',
    dark: '#B27B16',
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#D14343',
    light: '#DA6868',
    dark: '#922E2E',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#2196F3',
    light: '#64B6F7',
    dark: '#0B79D0',
    contrastText: '#FFFFFF',
  },
  brand: {
    // main: '#35373e',
    main: 'rgb(17, 24, 39)',
  },
  action: {
    active: colors.neutral[500],
    focus: 'rgba(55, 65, 81, 0.12)',
    hover: 'rgba(55, 65, 81, 0.04)',
    selected: 'rgba(55, 65, 81, 0.08)',
    disabledBackground: 'rgba(55, 65, 81, 0.12)',
    disabled: 'rgba(55, 65, 81, 0.26)',
  },
  text: {
    primary: '#121828',
    secondary: '#65748B',
    disabled: 'rgba(55, 65, 81, 0.48)',
  },
  background: { default: '#F9FAFC', paper: '#FFFFFF' },
  divider: '#E6E8F0',
  neutral: colors.neutral,
};
