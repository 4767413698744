import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  ColorInput as ColorInputBase,
  SelectInput,
  ColorInputProps,
  FormGetter,
  FormInput,
  Grid,
  FileInput,
  FormTabs,
  FormTab,
} from '@kirz/mui-admin';
import { Typography } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

const colors = [
  { key: 'welcomeMessageColor', name: 'Welcome message text color' },
  { key: 'toolbarColor', name: 'Bottom menu background color' },
  { key: 'toolbarTextActive', name: 'Bottom menu active text' },
  { key: 'toolbarTextInactive', name: 'Bottom menu inactive text' },
  { key: 'mainPanelBackgroundColor', name: 'Panel background color' },
  { key: 'carouselTitleColor', name: 'Carousel title color' },
  { key: 'carouselTextColor', name: 'Carousel text color' },
  { key: 'showAllColor', name: '"Show all" button color' },
  { key: 'alertColor', name: 'Top alert background color' },
  { key: 'alertTextColor', name: 'Top alert text color' },
  { key: 'primaryColor', name: 'Primary color' },
  { key: 'profileIconsColor', name: 'Profile icons color' },
  { key: 'skyOverlayColor', name: 'Sky overlay color' },
  {
    key: 'categorySwimlaneBackgroundColor',
    name: 'Category swimlane background color',
  },
  {
    key: 'categorySwimlaneTextColor',
    name: 'Category swimlane text color',
  },
];

function ColorInput(props: ColorInputProps<any>) {
  return (
    <FormGetter
      names={colors.map((x) => x.key)}
      render={(values) => (
        <ColorInputBase
          {...props}
          fetchColors={() => {
            return colors.map((x) => values[x.key]).filter((x) => !!x) as any;
          }}
        />
      )}
    />
  );
}

export const ColorSchemesTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'startHour',
            headerName: 'Hour',
            width: 200,
          },
          {
            field: 'name',
            headerName: 'Name',
            flex: 1,
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="colorScheme"
        columns={columns}
        persistStateMode="query"
        disableRemovedFilter
        deletable={false}
        // addButton={false}
        sortBy={rest.sortBy ?? { field: 'startHour', sort: 'asc' }}
        // editable={{
        //   onEdit(row) {
        //     tableRef.current?.openFormDialog(row);
        //   },
        // }}
        formTitle={(isNew) => (isNew ? 'New scheme' : 'Edit colors')}
        formDialogProps={{
          maxWidth: 'lg',
          formProps: {
            grid: false,
          },
        }}
      >
        <FormTabs
          tabs={[
            { id: 'main', label: 'General' },
            { id: 'background', label: 'Background' },
            { id: 'sun', label: 'Sun/Moon' },
          ]}
          tabsWrapperStyle={{
            mx: { xs: 0, md: 0 },
          }}
        >
          <FormTab tab="main">
            <Grid container spacing={2}>
              <FormInput name="name" label="Name" md={3} required />
              <FormInput
                name="startHour"
                label="Start hour"
                md={3}
                required
                type="number"
              />
              <Grid md={12} sx={{ mt: 2 }}>
                <Typography variant="body2">Colors</Typography>
              </Grid>
              <SelectInput
                name="statusBarColor"
                label="Status bar color"
                required
                md={4}
                items={[
                  { text: 'Light text', value: 'light-content' },
                  { text: 'Dark text', value: 'dark-content' },
                ]}
              />
              {colors.map((x) => (
                <ColorInput
                  key={x.key}
                  name={x.key}
                  label={x.name}
                  md={4}
                  required
                />
              ))}
            </Grid>
          </FormTab>
          <FormTab tab="background">
            <Grid container spacing={2}>
              <ColorInput
                name="mainGradientTop"
                label="Background gradient top"
                md={6}
                required
              />
              <ColorInput
                name="mainGradientBottom"
                label="Background gradient bottom"
                md={6}
                required
              />
              <FileInput
                name="backgroundImageId"
                label="Background image"
                helperText="Image will be scaled to fill the screen (preserving aspect ratio)"
                md={6}
              />
            </Grid>
          </FormTab>
          <FormTab tab="sun">
            <Grid container spacing={2}>
              <FileInput name="sunImageId" label="Image" md={6} />
              <Grid md={6}> </Grid>
              <FormInput
                name="sunImageWidth"
                label="Width (px)"
                md={4}
                required
                type="number"
              />
              <FormInput
                name="sunImageHeight"
                label="Height (px)"
                md={4}
                required
                type="number"
              />
              <FormInput
                name="sunOpacity"
                label="Opacity (0 - invisible, 1 - visible)"
                md={4}
                required
                type="number"
              />
              <FormInput
                name="sunImageX"
                label="X-coordinate (%)"
                md={6}
                required
                type="number"
                min={-10000}
              />
              <FormInput
                name="sunImageY"
                label="Y-coordinate (%)"
                md={6}
                required
                type="number"
                min={-10000}
              />
              <FormInput
                name="sunImageOffsetX"
                label="Image X-offset (px)"
                md={6}
                required
                type="number"
                min={-10000}
              />
              <FormInput
                name="sunImageOffsetY"
                label="Image Y-offset (px)"
                md={6}
                required
                type="number"
                min={-10000}
              />
              <Grid md={12}>
                <Typography variant="body2">Target image position:</Typography>
                <Typography
                  sx={{
                    borderRadius: 1,
                    border: 'thin solid rgba(0,0,0,0.3)',
                    p: 1,
                    fontFamily: 'monospace, monospace',
                    mt: 1,
                  }}
                >
                  X = screen_width&nbsp;&nbsp;* x_coord + image_offset_x
                  <br />Y = screen_height * y_coord + image_offset_y
                </Typography>
              </Grid>
            </Grid>
          </FormTab>
        </FormTabs>
      </DataTableEx>
    );
  },
);
