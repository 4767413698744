import {
  AutocompleteInput,
  CheckboxInput,
  ColorInput,
  DataTableExRef,
  FileInput,
  FormPageLayout,
  LanguagesContext,
  TranslationInput,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Meditation } from 'mdi-material-ui';
import React, { useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AudioVariantsTable } from 'components/AudioVariantsTable';

export function Audio() {
  const routeParams = useParams() as Record<string, string>;
  const { defaultLanguageId } = useContext(LanguagesContext);
  const audioId = parseInt(routeParams.id, 10);
  const tableRef = useRef<DataTableExRef>(null);

  return (
    <FormPageLayout
      source="audio"
      breadcrumbs={[
        {
          text: 'Meditations',
          icon: Meditation,
          href: '/meditations',
        },
        (item) => ({
          text: item.category?.name?.[defaultLanguageId],
          href: `/categories/${item.category?.id}`,
        }),
        (item) => ({
          text: `#${item.id} ${item.name?.[defaultLanguageId] || ''}`,
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['name', 'category']}
      defaultRoute="/meditations"
      formFetcherProps={{
        onSelection(selections) {
          return [...selections, 'category { id name }'];
        },
      }}
    >
      <Grid container alignItems="flex-start">
        <TranslationInput name="name" label="Name" required />
        <AutocompleteInput
          name="categoryId"
          source="category"
          mode="hasura"
          selection="id name"
          label="Category"
          itemText={(row) => row.name[defaultLanguageId]}
          itemValue="id"
          required
          md={5}
        />
        <AutocompleteInput
          name="afterMeditationMusicId"
          source="audio"
          mode="hasura"
          selection="id name"
          label="Music after meditation"
          itemText={(row) => row.name[defaultLanguageId]}
          itemValue="id"
          md={5}
        />
        <ColorInput name="color" label="Color" md={2} />
        <FileInput
          name="imageId"
          label="Image"
          sm={6}
          md={3}
          required
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />
        <FileInput
          name="bigImageId"
          label="Big image"
          sm={6}
          md={3}
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />
        <CheckboxInput name="isDownloadable" label="Download" sm="auto" />
        <CheckboxInput name="isActive" label="Active" sm="auto" />
      </Grid>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1">Variants</Typography>
          <Button
            startIcon={<Add />}
            variant="outlined"
            size="small"
            sx={{ mx: 2, whiteSpace: 'pre' }}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add new
          </Button>
        </Box>
        <AudioVariantsTable
          id="audio-variants-table"
          ref={tableRef}
          sx={{
            mt: 1,
            minHeight: 400,
            mx: -2,
            borderColor: 'transparent',
          }}
          selectProps={{
            filter: {
              audioId: { _eq: audioId },
            },
          }}
          formDialogProps={{
            formProps: {
              defaultValues: {
                audioId,
              },
            },
          }}
        />
      </Box>
    </FormPageLayout>
  );
}
