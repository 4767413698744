import {
  DataTable,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
  ConfigurationContext,
} from '@kirz/mui-admin';
import { Button } from '@mui/material';
import { FileExcel } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef, useState } from 'react';
import CsvDownloader from 'react-csv-downloader';

export function Clients() {
  const { hasura } = useContext(ConfigurationContext);

  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const tableRef = useRef<DataTableRef>(null);
  const clientsRef = useRef<any[]>([]);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      { field: 'firstName', headerName: 'First name', flex: 1 },
      { field: 'lastName', headerName: 'Last name', flex: 1 },
      { field: 'email', headerName: 'E-mail', flex: 1 },
      {
        field: 'subscription',
        headerName: 'Last purchase',
        valueGetter(params) {
          return params?.value?.title;
        },
      },
      {
        field: 'os',
        selector: 'platform',
        headerName: 'OS',
        valueGetter({ row }) {
          return row.platform
            ? `${row.platform.platform.toUpperCase()} v${
                row.platform.platformVersion
              }`
            : null;
        },
      },
      {
        field: 'version',
        selector: 'platform',
        headerName: 'App version',
        valueGetter({ row }) {
          return row.platform ? `v${row.platform.appVersion}` : null;
        },
      },
      {
        field: 'oneSignalUserId',
        headerName: 'PUSH-notifications',
        type: 'boolean',
        valueGetter({ value }) {
          return !!value;
        },
        width: 200,
      },
      { field: 'lastLoggedAt', headerName: 'Last active', type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Clients"
      actionContent={
        <CsvDownloader
          datas={async () => {
            const clients = await hasura.request({
              type: 'query',
              source: 'user',
              where: {
                role: { _eq: 'user' },
                isVerified: { _eq: true },
              },
              orderBy: [{ id: 'DESC' }],
              selection: ['id', 'firstName', 'lastName', 'email'],
            });

            clientsRef.current = clients;

            return clientsRef.current;
          }}
          filename="Clients.csv"
          style={{ marginLeft: 'auto' }}
        >
          <Button variant="contained" startIcon={<FileExcel />}>
            Export CSV
          </Button>
        </CsvDownloader>
      }
    >
      <DataTable
        id="clients-table"
        ref={tableRef}
        mode="hasura"
        source="user"
        columns={columns}
        disableRemovedFilter
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        selectProps={{
          filter: {
            role: { _eq: 'user' },
            isVerified: { _eq: true },
          },
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name or email',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { fullName: { _ilike: `%${str}%` } },
              { email: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
      />
      <FormDialog
        source="user"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={!selectedItem ? 'New user' : 'Edit user'}
        maxWidth="md"
        onSubmit={() => {
          tableRef.current?.reload();
        }}
        formProps={{
          defaultValues: { cardDarkTheme: false },
        }}
        formSubmitterProps={{
          mode: 'rest',
          url: () =>
            !selectedItem
              ? { url: '/users', method: 'POST' }
              : { url: `/users/${selectedItem.id}`, method: 'PUT' },
        }}
        formFetcherProps={{
          selector: {
            password: null,
          },
        }}
      >
        <FormInput name="firstName" label="First name" md={4} />
        <FormInput name="lastName" label="Last name" md={4} />
        <FormInput name="email" label="E-mail" type="email" md={4} required />
        <FormInput
          name="password"
          label="Password"
          type="password"
          md={6}
          required={!selectedItem}
          autoComplete="new-password"
          placeholder={selectedItem && 'Enter to change'}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
