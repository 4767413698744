import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  LanguagesContext,
} from '@kirz/mui-admin';
import React, {
  forwardRef,
  Ref,
  useContext,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

import { secondsToDuration } from 'utils/secondsToDuration';

export const ListeningHistoryTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;
    const { defaultLanguageId } = useContext(LanguagesContext);
    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'createdAt',
            headerName: 'Date',
            type: 'dateTime',
          },
          {
            field: 'audioVariant { duration audio { name } }',
            headerName: 'Meditation',
            flex: 1,
            onSort(sort) {
              return [
                { audioVariant: { audio: { name: sort } } },
                { audioVariant: { duration: sort } },
              ];
            },
            valueGetter: ({ row }) =>
              row.audioVariant
                ? `${row.audioVariant.audio.name[defaultLanguageId]} [${row.audioVariant.duration} min]`
                : '-',
          },
          {
            flex: 1,
            field: 'duration',
            headerName: 'Duration',
            valueFormatter({ value }) {
              return secondsToDuration(value);
            },
          },
          // {
          //   flex: 1,
          //   field: 'musicDuration',
          //   headerName: 'Music',
          //   valueFormatter({ value }) {
          //     return secondsToDuration(value);
          //   },
          // },
          {
            flex: 1,
            field: 'user { email }',
            headerName: 'User',
            type: 'email',
            valueGetter({ row }) {
              return row.user?.email;
            },
          },
          {
            flex: 1,
            field: 'isPremium',
            headerName: 'Premium?',
            type: 'boolean',
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="audioVariantListenSession"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'createdAt', sort: 'desc' }}
        formTitle={(isNew) => ''}
        editable={false}
      />
    );
  },
);
