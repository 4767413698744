import { Box } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { AppBar } from 'components/AppBar';
import { NavigationDrawer } from 'components/NavigationDrawer';

export function DefaultLayout() {
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <NavigationDrawer
        isDrawerOpened={isDrawerOpened}
        setIsDrawerOpened={setIsDrawerOpened}
      />
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          paddingTop: { xs: '56px', md: '64px' },
          maxHeight: '100vh',
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <AppBar
          onDrawerToggleClick={() => setIsDrawerOpened(!isDrawerOpened)}
        />
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Box
            component="main"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              p: { xs: 2, lg: 3 },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
