import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableExRef,
  ConfigurationContext,
  NotificationsContext,
} from '@kirz/mui-admin';
import { DiscountOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { AudiosTable } from 'components/AudiosTable';

export function Audios() {
  const [isFreeModeEnabled, setIsFreeModeEnabled] = useState<boolean | null>(
    null,
  );
  const [isFreeModeLoading, setIsFreeModeLoading] = useState(false);
  const tableRef = useRef<DataTableExRef>(null);
  const { hasura } = useContext(ConfigurationContext);
  const { showAlert } = useContext(NotificationsContext);

  const filterColumns = useCallback((columns: HasuraDataTableColumnDef[]) => {
    return columns;
  }, []);

  useEffect(() => {
    (async () => {
      const [{ freeMeditationsModeStartedAt }] = await hasura.request({
        type: 'query',
        source: 'settings',
        where: { id: { _eq: 1 } },
        selection: ['freeMeditationsModeStartedAt'],
      });

      setIsFreeModeEnabled(
        freeMeditationsModeStartedAt &&
          new Date(freeMeditationsModeStartedAt).valueOf() +
            1000 * 60 * 60 * 24 >
            new Date().valueOf(),
      );
    })();
  }, []);

  return (
    <TablePageLayout
      title="Meditations"
      actionContent={
        <>
          {isFreeModeEnabled !== null && (
            <Button
              sx={{ ml: 3 }}
              variant={!isFreeModeEnabled ? 'contained' : 'outlined'}
              startIcon={<DiscountOutlined />}
              disabled={isFreeModeLoading}
              onClick={async () => {
                setIsFreeModeLoading(true);

                await hasura.request({
                  type: 'mutation',
                  source: 'settings',
                  where: { id: { _eq: 1 } },
                  set: {
                    freeMeditationsModeStartedAt: isFreeModeEnabled
                      ? new Date(0).toISOString()
                      : new Date().toISOString(),
                  },
                  action: 'update',
                });

                if (!isFreeModeEnabled) {
                  setIsFreeModeEnabled(true);
                  showAlert(
                    'Done! Free mode will be enabled for 24 hours',
                    'success',
                  );
                } else {
                  setIsFreeModeEnabled(false);
                  showAlert('Done! Free mode disabled', 'success');
                }

                setTimeout(() => {
                  setIsFreeModeLoading(false);
                }, 300);
              }}
            >
              {!isFreeModeEnabled
                ? 'Make all meditations free'
                : 'Disable free mode'}
            </Button>
          )}
          <Button
            sx={{ ml: 'auto' }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              tableRef.current?.openFormDialog();
            }}
          >
            Add new
          </Button>
        </>
      }
    >
      <AudiosTable
        id="audios-table"
        ref={tableRef}
        filterColumns={filterColumns}
      />
    </TablePageLayout>
  );
}
