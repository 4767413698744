import { LanguagesContext, TablePageLayout } from '@kirz/mui-admin';
import React, { useContext } from 'react';

import { ListeningHistoryTable } from 'components/ListeningHistoryTable';

export function ListeningHistory() {
  const { defaultLanguageId } = useContext(LanguagesContext);

  return (
    <TablePageLayout title="Listening History">
      <ListeningHistoryTable
        id="listening-history-table"
        searchFilter={{
          inputProps: {
            placeholder: 'Search by meditation',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              {
                audioVariant: {
                  audio: {
                    name: { _cast: { String: { _ilike: `%${str}%` } } },
                  },
                },
              },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'User',
              field: 'userId',
              source: 'user',
              selection: 'id email',
              itemText: 'email',
              itemValue: 'id',
            },
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'Meditation',
              field: 'id',
              source: 'audio',
              selection: 'id name',
              itemText: (item) => item.name[defaultLanguageId] ?? '',
              itemValue: 'id',
              filter(value) {
                return {
                  audioVariant: {
                    audio: {
                      id: { _eq: value },
                    },
                  },
                };
              },
            },
          ],
        }}
      />
    </TablePageLayout>
  );
}
