import {
  ConfigurationContext,
  LanguagesContext,
  LanguagesContextType,
  useNavigate,
} from '@kirz/mui-admin';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { api } from 'services/api';
import { User } from 'types/user';

import { SettingsContext, SettingsContextType } from './SettingsContext';

type InitialUserContextType = {
  user?: User;
  isUserLoading: boolean;
  initialize?: () => Promise<void>;
  logout?: () => Promise<void>;
};

type UserContextType = {
  user: User;
  isUserLoading: boolean;
  initialize: () => Promise<void>;
  logout: () => Promise<void>;
  hasPermission: (permission: string) => boolean;
};

export const UserContext = createContext<UserContextType>({
  isUserLoading: true,
} as any);

type Props = {
  children: React.ReactNode;
};

export function UserContextProvider({ children }: Props) {
  const { hasura } = useContext(ConfigurationContext);
  const [isUserLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const [settings, setSettings] = useState<SettingsContextType>({} as any);
  const [languages, setLanguages] = useState<
    { id: number; name: string; code: string }[]
  >([]);

  const navigate = useNavigate();

  const fetchSettings = useCallback(async () => {
    try {
      const settingsData = (await api.getSettings()) as any;
      setSettings(settingsData);

      const items = await hasura.request({
        type: 'query',
        source: 'language',
        selection: 'id name code',
      });

      setLanguages(items);
    } catch {
      // no op
    }
  }, []);

  const initialize = useCallback(async () => {
    const [loggedUser] = await Promise.all([
      api.getMe().then(async (x) => {
        await fetchSettings();
        return x;
      }),
      new Promise((resolve) => {
        setTimeout(resolve, 400);
      }),
    ]);

    setUser(loggedUser);
    setIsLoading(false);

    api.initializeWsConnection();

    const isLoginPage = window.location.href.includes('/login');
    if (loggedUser && isLoginPage) {
      navigate('/');
      return;
    }

    if (!loggedUser) {
      navigate('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logout = useCallback(async () => {
    await api.logout();

    setUser(undefined);
  }, []);

  const hasPermission = useCallback(
    (permission: string) => {
      return user?.role === permission;
    },
    [user],
  );

  const contextData = useMemo<InitialUserContextType>(
    () => ({
      user,
      isUserLoading,
      logout,
      initialize,
      hasPermission,
    }),
    [user, isUserLoading, initialize, hasPermission],
  );

  const settingsContextData = useMemo<SettingsContextType>(
    () => ({
      ...settings,
      reload: fetchSettings,
    }),
    [settings, fetchSettings],
  );

  const languagesContextData = useMemo<LanguagesContextType>(
    () => ({
      languages,
      defaultLanguageId: languages?.[0]?.id ?? 0,
    }),
    [languages],
  );

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <LanguagesContext.Provider value={languagesContextData}>
      <UserContext.Provider value={contextData as UserContextType}>
        <SettingsContext.Provider value={settingsContextData!}>
          {children}
        </SettingsContext.Provider>
      </UserContext.Provider>
    </LanguagesContext.Provider>
  );
}
