import {
  FormInput,
  SelectInput,
  SubmitButton,
  Form,
  VerificationCodeInput,
  Grid,
} from '@kirz/mui-admin';
import {
  Alert,
  Container,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useContext, useState } from 'react';

import { CompanyLogo } from 'components/CompanyLogo';
import { UserContext } from 'contexts/UserContext';
import { api } from 'services/api';
import { LoginError, LoginPayload, UserIdentity } from 'types/auth';

export function Login() {
  const { initialize } = useContext(UserContext);
  const [loginStep, setLoginStep] = useState<
    'email' | 'identity' | 'code' | 'password'
  >('email');
  const [identities, setIdentities] = useState<UserIdentity[]>([]);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const theme = useTheme();

  const handleSubmit = async (payload: LoginPayload) => {
    const { success, errorCode, errorData } = await api.login(payload);
    if (success) {
      await initialize!();
      return;
    }

    setErrorMessage(null);
    setInfoMessage(null);

    switch (errorCode) {
      // case LoginError.PHONE_VALIDATION_REQUIRED: {
      //   setLoginStep('code');
      //   setInfoMessage('Verification code was sent to your email');
      //   break;
      // }
      case LoginError.IDENTITY_SELECTION_REQUIRED: {
        setLoginStep('identity');
        setIdentities(errorData.identities as UserIdentity[]);
        setInfoMessage('Your accouunt has multiple access levels');
        break;
      }
      case LoginError.PASSWORD_VALIDATION_REQUIRED: {
        setLoginStep('password');
        setInfoMessage('Enter your password');
        break;
      }
      case LoginError.IDENTITY_NOT_FOUND: {
        setErrorMessage('User not found');
        setLoginStep('email');
        break;
      }
      case LoginError.WRONG_PASSWORD: {
        setErrorMessage('Wrong password');
        break;
      }
      case LoginError.WRONG_VALIDATION_CODE: {
        setErrorMessage('Wrong verification code');
        break;
      }
      default: {
        throw new Error(`Unknown error: ${errorCode}`);
      }
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}
    >
      <Paper elevation={16} sx={{ flex: 1, p: 4, width: '100%' }}>
        <Stack
          alignItems="center"
          spacing={1}
          sx={{ position: 'relative', pt: 2 }}
        >
          <CompanyLogo
            htmlColor={theme.palette.brand.main}
            sx={{
              width: 50,
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          />
          <Typography variant="h4">Login</Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Authorization in CMS
          </Typography>
          <Form
            sx={{ pt: 2, alignSelf: 'stretch' }}
            onSubmit={handleSubmit}
            spacing={3}
            shouldUnregister={false}
            dirtySubmit={false}
          >
            {infoMessage && (
              <Grid xs={12}>
                <Alert severity="info">{infoMessage}</Alert>
              </Grid>
            )}
            {errorMessage && (
              <Grid xs={12}>
                <Alert severity="error">{errorMessage}</Alert>
              </Grid>
            )}

            <FormInput
              label="E-mail"
              clearable
              required
              name="email"
              type="email"
              disabled={loginStep !== 'email'}
              autoFocus
              placeholder="your-email@gmail.com"
            />
            <FormInput
              label="Password"
              type="password"
              clearable
              required
              name="password"
              autoFocus
            />

            <SubmitButton
              variant="contained"
              size="large"
              grid
              fullWidth
              xs={12}
            >
              Continue
            </SubmitButton>
          </Form>
        </Stack>
      </Paper>
    </Container>
  );
}
