import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  LanguagesContext,
  DateInput,
  FileInput,
  ConfigurationContext,
  Grid,
  FormGetter,
  FormSetter,
  AutocompleteInput,
  ColorInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { FontFamilies, TextAlignments, TextPositions } from 'constants/other';

export function Quotes() {
  const tableRef = useRef<DataTableExRef>(null);
  const { defaultLanguageId } = useContext(LanguagesContext);
  const { hasura } = useContext(ConfigurationContext);
  const [images, setImages] = useState<number[] | null>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        type: 'date',
        width: 100,
      },
      {
        field: 'imageId',
        headerName: 'Image',
        type: 'file',
        hideText: true,
        width: 80,
        sortable: false,
      },
      {
        flex: 1,
        field: 'text',
        headerName: 'Text',
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'date',
        width: 100,
      },
    ],
    [defaultLanguageId],
  );

  return (
    <TablePageLayout
      title="Quotes"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTableEx
        id="quotes-table"
        ref={tableRef}
        source="quote"
        columns={columns}
        sortBy={{ field: 'date', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) => (isNew ? 'New Quote' : 'Edit Quote')}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by text',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ text: { _ilike: `%${str}%` } }]),
          }),
        }}
        formDialogProps={{
          maxWidth: 'md',
          formProps: {
            defaultValues: {
              textSize: 20,
              testUserId: null,
              fontFamily: 'Roboto',
              textAlignment: 'center',
              textPosition: 'center-center',
              textColor: '#FFFFFF',
            },
          },
          async onOpen() {
            const items = await hasura.request({
              type: 'query',
              source: 'quote',
              selection: 'imageId',
              distinctOn: ['imageId'],
            });

            setImages(items.map((x: any) => x.imageId));
          },
          onClose() {
            setTimeout(() => {
              setImages(null);
            }, 100);
          },
        }}
      >
        <DateInput label="Date" name="date" required md={4} />
        <AutocompleteInput
          name="testUserId"
          source="user"
          mode="hasura"
          selection="id email"
          label="Tester"
          itemText="email"
          itemValue="id"
          md={8}
          fetchAll={false}
        />
        <FormGetter
          names={['fontFamily']}
          render={({ fontFamily }) => (
            <AutocompleteInput
              name="fontFamily"
              label="Font"
              options={FontFamilies}
              itemText="text"
              itemValue="value"
              md={4}
              required
              sx={{
                '& input': {
                  fontFamily:
                    FontFamilies.find((x) => x.value === fontFamily)?.text +
                    (fontFamily === 'Salsa'
                      ? ', cursive'
                      : fontFamily === 'UbuntuMono'
                      ? ', monospace'
                      : ', sans-serif'),
                },
              }}
              renderOption={(props, option, state) => (
                <Box
                  {...props}
                  component="li"
                  sx={{
                    fontFamily:
                      FontFamilies.find((x) => x.value === option.value)?.text +
                      (option.value === 'Salsa'
                        ? ', cursive'
                        : option.value === 'UbuntuMono'
                        ? ', monospace'
                        : ', sans-serif'),
                  }}
                >
                  {option.text}
                </Box>
              )}
            />
          )}
        />

        <AutocompleteInput
          name="textAlignment"
          label="Text alignment"
          options={TextAlignments}
          itemText="text"
          itemValue="value"
          md={4}
          required
        />
        <AutocompleteInput
          name="textPosition"
          label="Text position"
          options={TextPositions}
          itemText="text"
          itemValue="value"
          md={4}
          required
        />
        <ColorInput name="textColor" required md={4} />
        <FormInput
          label="Font size"
          name="textSize"
          type="number"
          required
          md={4}
        />
        <Grid xs="auto" md={4} />
        <FileInput
          name="imageId"
          label="Image"
          required
          md={4}
          helperText="Landscape or square image preferred"
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />
        <FormInput
          name="text"
          label="Text"
          required
          multiline
          rows={3}
          md={8}
        />
        <Grid xs={12} sx={{ mb: -2, mt: 1 }}>
          <Typography variant="body2">Presets</Typography>
        </Grid>
        {!images ? (
          <Grid xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={24} />
          </Grid>
        ) : (
          <FormGetter
            names={['imageId']}
            render={(imageId) => (
              <FormSetter
                render={(setValue) => (
                  <Grid xs={12}>
                    <Grid container xs={12}>
                      {images.map((imageId) => (
                        <Grid key={imageId} xs={12} sm={3}>
                          <Box
                            component="img"
                            src={`/api/v1/files/${imageId}`}
                            onClick={() => {
                              setValue('imageId', imageId);
                            }}
                            sx={{
                              width: '100%',
                              height: 70,
                              objectFit: 'cover',
                              cursor: 'pointer',
                              transition: 'linear 0.1s',
                              borderWidth: 1,
                              borderStyle: 'solid',
                              ':hover': {
                                transform: 'scale(1.02)',
                                borderColor: 'gray',
                              },
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                )}
              />
            )}
          />
        )}
      </DataTableEx>
    </TablePageLayout>
  );
}
