import {
  ColorInput,
  Form,
  FormElementRef,
  FormFetcher,
  FormInput,
  FormSubmitter,
  Grid,
  SubmitButton,
  TablePageLayout,
} from '@kirz/mui-admin';
import SaveIcon from '@mui/icons-material/Save';
import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useRef } from 'react';

import { ColorSchemesTable } from 'components/ColorSchemesTable';

export function Settings() {
  const formRef = useRef<FormElementRef>(null);

  return (
    <TablePageLayout
      title="System parameters"
      actionContent={
        <SubmitButton
          sx={{ ml: 'auto' }}
          variant="contained"
          loadingPosition="start"
          startIcon={<SaveIcon />}
          formRef={formRef}
          activateOnDirty
        >
          Save
        </SubmitButton>
      }
    >
      <FormSubmitter source="settings" entityId={{ id: { _eq: 1 } }}>
        <FormFetcher source="settings" entityId={{ id: { _eq: 1 } }}>
          <Form ref={formRef} sx={{ pt: 1 }}>
            <Grid md={12}>
              <Typography variant="body2">Colors</Typography>
            </Grid>
            <ColorInput
              name="primaryColor"
              label="Main primary color (purple)"
              md={3}
              required
            />
            <ColorInput
              name="dialogBoxPrimaryColor"
              label="Modal windows primary color"
              md={3}
              required
            />
            <FormInput
              name="trialListenPeriod"
              label="Trial listen period"
              type="number"
              md={3}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">seconds</InputAdornment>
                ),
              }}
            />
            <FormInput
              name="quoteLogoSize"
              label="Quote logo size"
              type="number"
              md={3}
              required
            />
            <FormInput
              name="quoteBottomOffset"
              label="Quote logo bottom offset"
              type="number"
              md={3}
              required
            />
          </Form>
        </FormFetcher>
      </FormSubmitter>
      <Box
        sx={{
          minHeight: 400,
          flexGrow: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          mt: { xs: 2, md: 4, lg: 7 },
        }}
      >
        <ColorSchemesTable id="color-schemes-table" title="Color schemes" />
      </Box>
    </TablePageLayout>
  );
}
