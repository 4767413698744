import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  LanguagesContext,
} from '@kirz/mui-admin';
import React, { useContext, useMemo, useRef } from 'react';

export function Feedback() {
  const tableRef = useRef<DataTableExRef>(null);
  const { defaultLanguageId } = useContext(LanguagesContext);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        width: 200,
        field: 'user { email }',
        headerName: 'User',
        type: 'email',
        valueGetter({ row }) {
          return row.user?.email;
        },
      },
      {
        field: 'audioVariant { duration audio { name } }',
        headerName: 'Meditation',
        width: 300,
        onSort(sort) {
          return [
            { audioVariant: { audio: { name: sort } } },
            { audioVariant: { duration: sort } },
          ];
        },
        valueGetter: ({ row }) =>
          row.audioVariant
            ? `${row.audioVariant.audio.name[defaultLanguageId]} [${row.audioVariant.duration} min.]`
            : '-',
      },
      {
        width: 100,
        field: 'stars',
        headerName: 'Stars',
      },
      {
        field: 'text',
        headerName: 'Text',
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'date',
        width: 100,
      },
    ],
    [defaultLanguageId],
  );

  return (
    <TablePageLayout title="Feedback">
      <DataTableEx
        id="feedback-table"
        ref={tableRef}
        source="feedback"
        columns={columns}
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) => (isNew ? 'New Gift' : 'Feedback')}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by email',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { user: { email: { _ilike: `%${str}%` } } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'User',
              field: 'userId',
              source: 'user',
              selection: 'id email',
              itemText: 'email',
              itemValue: 'id',
            },
            {
              mode: 'hasura',
              type: 'autocomplete',
              name: 'Meditation',
              field: 'id',
              source: 'audio',
              selection: 'id name',
              itemText: (item) => item.name[defaultLanguageId] ?? '',
              itemValue: 'id',
              filter(value) {
                return {
                  audioVariant: {
                    audio: {
                      id: { _eq: value },
                    },
                  },
                };
              },
            },
          ],
        }}
      >
        <FormInput name="stars" label="Stars" required type="number" />
        <FormInput name="text" label="Text" required multiline rows={10} />
      </DataTableEx>
    </TablePageLayout>
  );
}
