import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  AutocompleteInput,
  Grid,
  CheckboxInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Button, Typography } from '@mui/material';
import React, { useContext, useMemo, useRef, useState } from 'react';

import { UserContext } from 'contexts/UserContext';
import { api } from 'services/api';

function ExportButton({
  id,
  ...props
}: { id: number } & Omit<LoadingButtonProps, 'id'>) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingButton
      variant="contained"
      size="small"
      color="secondary"
      onClick={async () => {
        try {
          setIsLoading(true);

          await api.sendGift(id);
        } finally {
          setIsLoading(false);
        }
      }}
      loading={isLoading}
      {...props}
    />
  );
}
export function Gifts() {
  const { user } = useContext(UserContext);
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'sender',
        headerName: 'Sender',
        selector: 'senderFirstName senderLastName',
        flex: 1,
        onSort(sort) {
          return [{ senderFirstName: sort }];
        },
        valueGetter: ({ row }) =>
          `${row.senderFirstName} ${row.senderLastName}`,
      },
      {
        flex: 1,
        field: 'senderEmail',
        headerName: 'Sender email',
        type: 'email',
      },
      {
        field: 'recipient',
        headerName: 'Recipient',
        selector: 'recipientFirstName recipientLastName',
        flex: 1,
        onSort(sort) {
          return [{ senderFirstName: sort }];
        },
        valueGetter: ({ row }) =>
          `${row.senderFirstName} ${row.senderLastName}`,
      },
      {
        field: 'recipientEmail',
        headerName: 'Recipient email',
        flex: 1,
        type: 'email',
      },
      {
        width: 100,
        headerName: 'Product',
        field: 'product { *name* }',
        type: 'relationship',
      },
      {
        width: 100,
        field: 'isCompleted',
        headerName: 'Paid',
        type: 'boolean',
      },
      {
        width: 100,
        field: 'user { id }',
        headerName: 'Activated',
        type: 'boolean',
        valueGetter({ row }) {
          return !!row.user;
        },
      },
      {
        field: 'createdAt',
        headerName: 'Created at',
        type: 'date',
        width: 100,
      },
      {
        width: 140,
        field: 'actions',
        headerName: '',
        selector: 'sendToRecipient',
        renderCell({ row }) {
          return (
            <ExportButton id={row.id} disabled={row.user}>
              Send to {row.sendToRecipient ? 'recipient' : 'sender'}
            </ExportButton>
          );
        },
      },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Gifts"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTableEx
        id="gifts-table"
        ref={tableRef}
        source="gift"
        columns={columns}
        sortBy={{ field: 'createdAt', sort: 'desc' }}
        persistStateMode="query"
        formTitle={(isNew) => (isNew ? 'New Gift' : 'Edit Gift')}
        formDialogProps={{
          autoFocus: false,
          maxWidth: 'md',
          formProps: {
            defaultValues: {
              senderFirstName: user.firstName,
              senderLastName: user.lastName,
              senderEmail: user.email,
              sendToRecipient: true,
            },
          },
          formSubmitterProps: {
            preSubmit(item) {
              return {
                ...item,
                stripeProductId: 'manual',
                isCompleted: true,
                completedAt: new Date().toISOString(),
              };
            },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by email',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { recipientEmail: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
      >
        <Grid xs={12}>
          <Typography variant="body2">Sender</Typography>
        </Grid>
        <Grid xs={12} container>
          <FormInput
            name="senderFirstName"
            label="First name"
            required
            md={4}
          />
          <FormInput name="senderLastName" label="Last name" required md={4} />
          <FormInput name="senderEmail" label="Email" required md={4} />
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2">Recipient</Typography>
        </Grid>
        <Grid xs={12} container>
          <FormInput
            name="recipientFirstName"
            label="First name"
            required
            md={4}
          />
          <FormInput
            name="recipientLastName"
            label="Last name"
            required
            md={4}
          />
          <FormInput name="recipientEmail" label="Email" required md={4} />
        </Grid>
        <Grid xs={12}>
          <Typography variant="body2">Gift</Typography>
        </Grid>
        <AutocompleteInput
          name="productId"
          label="Product"
          required
          mode="hasura"
          source="product"
          selection="id name"
          itemText="name"
          itemValue="id"
          md={4}
        />
        <CheckboxInput
          name="sendToRecipient"
          label="Send to recipient"
          md={4}
        />
        <FormInput name="comment" label="Text content" multiline rows={3} />
      </DataTableEx>
    </TablePageLayout>
  );
}
