import {
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  CheckboxInput,
  ConfigurationContext,
  FileInput,
  TranslationInput,
  DateInput,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

export function Categories() {
  const tableRef = useRef<DataTableExRef>(null);
  const { hasura } = useContext(ConfigurationContext);
  const [sortValues, setSortValues] = useState<number[] | null>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'serialNumber',
        type: 'sort',
        sortKey: 'serialNumber',
        rowsSortValues: sortValues,
        onSortChange: async (direction, row, targetRowSort) => {
          const source = 'Category';
          const { id } = row;

          await hasura.request({
            type: 'custom',
            query: `
            mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
              result2: update${source}(where: $where2, _set: $set2) {
                affected_rows
              }
              result1: update${source}(where: $where1, _set: $set1) {
                affected_rows
              }
            }
          `,
            variables: {
              where1: { id: { _eq: id } },
              set1: { serialNumber: targetRowSort },
              where2: { serialNumber: { _eq: targetRowSort } },
              set2: { serialNumber: row.serialNumber },
            },
          });

          tableRef.current?.reload();
        },
      },
      {
        field: 'imageId',
        headerName: 'Image',
        type: 'file',
        sortable: false,
        hideText: true,
        width: 80,
      },
      {
        field: 'bigImageId',
        headerName: 'Big image',
        type: 'file',
        sortable: false,
        hideText: true,
        width: 80,
      },
      {
        field: 'name',
        headerName: 'Name',
        type: 'translation',
        flex: 1,
      },
      {
        field: 'popularity',
        headerName: 'Popularity',
        valueFormatter({ value }) {
          return `${value}%`;
        },
        sortable: false,
      },
      {
        field: 'newUntil',
        headerName: 'New',
        type: 'boolean',
        sortable: false,
        valueGetter({ value }) {
          return value && dayjs(value) > dayjs();
        },
      },
      {
        field: 'isActive',
        headerName: 'Active',
        type: 'boolean',
        sortable: false,
      },
    ],
    [sortValues],
  );

  useEffect(() => {
    (async () => {
      const categories = await hasura.request({
        type: 'query',
        source: 'category',
        selection: 'serialNumber',
      });

      setSortValues(categories.map((x: any) => x.serialNumber));
    })();
  }, []);

  return (
    <TablePageLayout
      title="Categories"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTableEx
        id="categories-table"
        ref={tableRef}
        source="category"
        columns={columns}
        editPageUrl="/categories"
        deletable={{
          onDeleted: (row) => {
            setSortValues(sortValues!.filter((x) => x !== row.serialNumber));
          },
        }}
        sortBy={{ field: 'serialNumber', sort: 'asc' }}
        persistStateMode="query"
        formDialogProps={{
          autoFocus: false,
          formProps: {
            defaultValues: {
              isActive: false,
            },
          },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { defaultName: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
        customFilter={{
          filters: [
            {
              type: 'boolean',
              name: 'Is active',
              field: 'isActive',
            },
          ],
        }}
        formTitle={(isNew) => (isNew ? 'New category' : 'Edit category')}
      >
        <TranslationInput
          name="name"
          label="Name"
          required
          gridCols={{ md: 6, lg: 6, xl: 6 }}
        />
        <DateInput name="newUntil" label="New until" md={6} />
        <CheckboxInput name="isActive" label="Active" md={6} />
        <FileInput
          name="imageId"
          label="Image"
          required
          dropzoneProps={{
            accept: {
              'image/png': ['.png'],
              'image/jpeg': ['.jpg', '.jpeg'],
            },
          }}
        />
      </DataTableEx>
    </TablePageLayout>
  );
}
