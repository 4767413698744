import { NavigationContext } from '@kirz/mui-admin';
import MenuIcon from '@mui/icons-material/Menu';
import { AppBar as MuiAppBar, Box, IconButton, Toolbar } from '@mui/material';
import React, { useContext } from 'react';

import { TOOLBAR_HEIGHT } from 'constants/theme';

import { AppBarAccount } from './AppBarAccount';

type Props = {
  onDrawerToggleClick: () => void;
};

export function AppBar({ onDrawerToggleClick }: Props) {
  const { setNavbarContainer } = useContext(NavigationContext);

  return (
    <MuiAppBar
      position="absolute"
      sx={{
        left: { xs: 0 },
        width: { xs: '100%' },
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'background.paper',
        height: TOOLBAR_HEIGHT,
        color: (theme) => theme.palette.text.primary,
        // display: { xs: 'auto', lg: 'none' },
      }}
    >
      <Toolbar sx={{ position: 'relative', display: 'flex' }}>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          sx={{ mr: 2, display: { xs: 'flex', lg: 'none' } }}
          onClick={onDrawerToggleClick}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            pl: 0,
            pr: 5,
            display: 'flex',
            alignItems: 'center',
          }}
          ref={(ref) => {
            setNavbarContainer(ref as any);
          }}
        />
        <AppBarAccount />
      </Toolbar>
    </MuiAppBar>
  );
}
