export function secondsToDuration(seconds: number) {
  const date = new Date(0);
  date.setSeconds(seconds);
  const timeString = date.toISOString().substring(11, 19);

  if (timeString.startsWith('00:')) {
    return timeString.replace('00:', '');
  }

  return timeString;
}
