import {
  ConfigurationProvider as MuiAdminConfigurationProvider,
  NavigationContextProvider,
} from '@kirz/mui-admin';
import React, { useContext, useEffect } from 'react';
import {
  Routes as DomRoutes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom';

import { RouteGuard } from 'components/RouteGuard';
import { SplashLoader } from 'components/SplashLoader';
import { Configuration } from 'constants/configuration';
import { UserContext } from 'contexts/UserContext';
import { DefaultLayout } from 'layouts/DefaultLayout';
import { Audio } from 'pages/Audio';
import { Audios } from 'pages/Audios';
import { Categories } from 'pages/Categories';
import { Category } from 'pages/Category';
import { Clients } from 'pages/Clients';
import { Feedback } from 'pages/Feedback';
import { Gifts } from 'pages/Gifts';
import { Language } from 'pages/Language';
import { Languages } from 'pages/Languages';
import { ListeningHistory } from 'pages/ListeningHistory';
import { Login } from 'pages/Login';
import { Notifications } from 'pages/Notifications';
import { Quotes } from 'pages/Quotes';
import { Settings } from 'pages/Settings';
import { Users } from 'pages/Users';
import { VideoLane } from 'pages/VideoLane';
import { VideoLanes } from 'pages/VideoLanes';

export default function App() {
  const { isUserLoading, user, hasPermission } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserLoading || user) {
      return;
    }

    navigate('/login');
  }, [navigate, isUserLoading, user]);

  const routes = [
    {
      path: 'clients',
      element: <Clients />,
    },
    {
      path: 'categories',
      children: (
        <>
          <Route index element={<Categories />} />
          <Route path=":id" element={<Category />} />
        </>
      ),
    },
    {
      path: 'video-lanes',
      children: (
        <>
          <Route index element={<VideoLanes />} />
          <Route path=":id" element={<VideoLane />} />
        </>
      ),
    },
    {
      path: 'meditations',
      children: (
        <>
          <Route index element={<Audios />} />
          <Route path=":id" element={<Audio />} />
        </>
      ),
    },
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'notifications',
      element: <Notifications />,
    },
    {
      path: 'gifts',
      element: <Gifts />,
    },
    {
      path: 'feedback',
      element: <Feedback />,
    },
    {
      path: 'quotes',
      element: <Quotes />,
    },
    {
      path: 'listening-history',
      element: <ListeningHistory />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },
    {
      path: 'languages',
      children: (
        <>
          <Route index element={<Languages />} />
          <Route path=":id" element={<Language />} />
        </>
      ),
    },
  ] as {
    path: string;
    permission?: string | string[];
    children?: React.ReactNode;
    element?: React.ReactNode;
  }[];

  const defaultRoute =
    routes.find(
      (x) =>
        !x.permission ||
        (typeof x.permission === 'string'
          ? hasPermission(x.permission)
          : !!x.permission.find((y) => hasPermission(y))),
    )?.path || '/';

  return (
    <MuiAdminConfigurationProvider {...Configuration().muiAdmin}>
      <NavigationContextProvider>
        <DomRoutes>
          <Route path="login" element={<Login />} />
          {user && (
            <>
              <Route path="/" element={<DefaultLayout />}>
                {routes.map((route) => {
                  const content = route.children || (
                    <Route index element={route.element} />
                  );

                  return (
                    <Route
                      key={route.path}
                      path={route.path}
                      element={<RouteGuard permission={route.permission} />}
                    >
                      {content}
                    </Route>
                  );
                })}
                <Route index element={<Navigate to={defaultRoute} />} />
              </Route>
              <Route path="*" element={<Navigate to={defaultRoute} />} />
            </>
          )}
        </DomRoutes>
        <SplashLoader visible={isUserLoading} />
      </NavigationContextProvider>
    </MuiAdminConfigurationProvider>
  );
}
