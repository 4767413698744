import axios, { AxiosError, AxiosResponse } from 'axios';
import { Client, createClient } from 'graphql-ws';

import { LoginPayload, LoginResponse } from 'types/auth';
import { User } from 'types/user';

export class Api {
  constructor(
    public readonly client = axios.create({ baseURL: '/api/v1' }),
    public readonly hasuraClient = axios.create({
      baseURL: '/v1/graphql',
    }),
    private hasuraWsClient: Client | null = null,
    private readonly hasuraMutationClient = axios.create({
      baseURL: '/api/v1/hasura/mutation',
    }),
  ) {
    const responseInterceptor = (response: AxiosResponse<any, any>) => {
      if (response.data?.errors?.length) {
        if (
          response.data?.errors[0]?.message?.includes(`not found in type: '`) &&
          !window.location.pathname.includes('/login')
        ) {
          window.location.href = '/login';
        }
      }
      return response;
    };

    const errorInterceptor = (error: AxiosError) => {
      if (
        !window.location.pathname.includes('/login') &&
        (error.response?.status === 403 || error.response?.status === 401)
      ) {
        window.location.href = '/login';
      }
      return Promise.reject(error);
    };

    this.client.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    );

    this.hasuraClient.interceptors.response
      .use
      // responseInterceptor,
      // errorInterceptor,
      ();

    this.hasuraMutationClient.interceptors.response.use(
      responseInterceptor,
      errorInterceptor,
    );
  }

  get instance() {
    return this.client;
  }

  get hasuraClientInstance() {
    return this.hasuraClient;
  }

  get hasuraWsClientInstance() {
    return this.hasuraWsClient;
  }

  get hasuraMutationClientInstance() {
    return this.hasuraMutationClient;
  }

  initializeWsConnection() {
    this.hasuraWsClient = createClient({
      url:
        process.env.NODE_ENV === 'development'
          ? 'ws://localhost:8082/v1/graphql'
          : `wss://${window.location.host}/v1/graphql`,
      shouldRetry: () => true,
    });
  }

  async getMe() {
    try {
      const { data } = await this.client.get<User>('/auth');
      return data;
    } catch {
      return undefined;
    }
  }

  async sendGift(id: number) {
    await this.client.post(`/gifts/${id}/send`);
  }

  async getSettings() {
    // const {
    //   data: {
    //     data: {
    //       settings: [settings],
    //       tags,
    //     },
    //   },
    // } = await this.hasuraClientInstance.post<{
    //   data: { settings: any[]; tags: any[] };
    // }>('', {
    //   query: `
    //     {
    //       settings: settings {
    //         leadSources dealCancelReasons nonTargetedLeadType sellingCommissionSumFunc partnerCommissionSumFunc
    //         developerCommissionSumFunc agencyCommissionSumFunc expertCommissionSumFunc totalIncomeFunc
    //         taxFunc yearlyIncomeFunc adsPriceFunc agencyCommissionValues expertCommissionValues minActualPriceCorrectionPercent
    //         maxActualPriceCorrectionPercent minMortgageActualPriceCorrectionPercent maxMortgageActualPriceCorrectionPercent
    //       }

    //       tags: tag {
    //         id name icon color systemType entity
    //       }
    //     }`
    //     .replace(/\n/g, ' ')
    //     .replace(/ +/g, ' ')
    //     .trim(),
    // });

    // return { ...settings, tags };
    return {};
  }

  async login(payload: LoginPayload): Promise<LoginResponse> {
    const { data } = await this.client.post<LoginResponse>('/auth/login', {
      ...payload,
      admin: true,
    });

    return data;
  }

  async logout(): Promise<void> {
    await this.client.post('/auth/logout');
  }
}

export const api = new Api();
