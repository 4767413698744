import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef } from 'react';

export function Languages() {
  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      { field: 'name', headerName: 'Name', flex: 1, sortable: false },
      { field: 'code', headerName: 'Code', flex: 1, sortable: false },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Languages"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            tableRef.current?.openFormDialog();
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTableEx
        id="languages-table"
        ref={tableRef}
        source="language"
        columns={columns}
        editPageUrl="/languages"
        persistStateMode="query"
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [{ name: { _ilike: `%${str}%` } }]),
          }),
        }}
        formTitle={() => 'New language'}
        formDialogProps={{
          autoFocus: false,
          maxWidth: 'sm',
        }}
      >
        <FormInput name="name" label="Name" required />
        <FormInput name="code" label="Language code" required />
      </DataTableEx>
    </TablePageLayout>
  );
}
