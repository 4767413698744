import { AbsoluteBox } from '@kirz/mui-admin';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

import { CompanyLogo } from 'components/CompanyLogo';
import { LogoLoaderAnimation } from 'theme/animations';

type Props = {
  visible: boolean;
};

export function SplashLoader({ visible }: Props) {
  const theme = useTheme();

  return (
    <AbsoluteBox
      sx={{
        ...(!visible && { opacity: 0 }),
        bgcolor: theme.palette.brand.main,
        pointerEvents: !visible ? 'none' : 'initial',
        transition: 'opacity .1s',
        zIndex: 10000,
        position: 'fixed',
        WebkitTransform: 'translateZ(0)',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        <CompanyLogo sx={{ width: 82, animation: LogoLoaderAnimation }} />
      </Box>
      <Box sx={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}>
        {/* font preload */}
        {['400', '500', '600', '700'].map((fontWeight) => (
          <Typography key={fontWeight} sx={{ fontWeight }}>
            {fontWeight}
            {/* latin */}
            aA
          </Typography>
        ))}
      </Box>
    </AbsoluteBox>
  );
}
