import { SvgIcon, SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';

import { ReactComponent as LogoIcon } from 'assets/icons/logo.svg';

type Props = {
  htmlColor?: string;
  sx?: SxProps<Theme>;
};

export function CompanyLogo({ sx, htmlColor = 'white' }: Props) {
  return (
    <SvgIcon
      component={LogoIcon}
      htmlColor={htmlColor}
      inheritViewBox
      sx={{
        width: 'auto',
        height: 'auto',
        ...sx,
      }}
    />
  );
}
