import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FormInput,
  ConfigurationContext,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, {
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

export const FaqQuestionsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      languageId: number;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { languageId, filterColumns, ...rest } = props;
    const tableRef = useRef<DataTableExRef>(null);
    const { hasura } = useContext(ConfigurationContext);
    const [sortValues, setSortValues] = useState<number[] | null>(null);

    useEffect(() => {
      (async () => {
        const items = await hasura.request({
          type: 'query',
          source: 'faqQuestion',
          selection: 'serialNumber',
        });

        setSortValues(items.map((x: any) => x.serialNumber));
      })();
    }, []);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'serialNumber',
            type: 'sort',
            sortKey: 'serialNumber',
            rowsSortValues: sortValues,
            onSortChange: async (direction, row, targetRowSort) => {
              const source = 'FaqQuestion';
              const { id } = row;

              await hasura.request({
                type: 'custom',
                query: `
                  mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
                    result2: update${source}(where: $where2, _set: $set2) {
                      affected_rows
                    }
                    result1: update${source}(where: $where1, _set: $set1) {
                      affected_rows
                    }
                  }
                `,
                variables: {
                  where1: { id: { _eq: id } },
                  set1: { serialNumber: targetRowSort },
                  where2: { serialNumber: { _eq: targetRowSort } },
                  set2: { serialNumber: row.serialNumber },
                },
              });

              tableRef.current?.reload();
            },
          },
          {
            headerName: 'Question',
            field: 'question',
            flex: 1,
            minWidth: 100,
            languageId,
            type: 'translation',
          },
          {
            headerName: 'Answer',
            field: 'answer',
            flex: 1,
            minWidth: 100,
            languageId,
            type: 'translation',
          },
        ]),
      [filterColumns, sortValues],
    );

    return (
      //
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="faqQuestion"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'serialNumber', sort: 'asc' }}
        searchFilter={
          {
            slots: {
              beforeSearch: (
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <Typography variant="subtitle1">FAQ</Typography>
                  <Button
                    startIcon={<Add />}
                    variant="outlined"
                    size="small"
                    sx={{ mx: 2, whiteSpace: 'pre' }}
                    onClick={() => {
                      tableRef.current?.openFormDialog();
                    }}
                  >
                    Add new
                  </Button>
                </Box>
              ),
            },
          } as any
        }
        formTitle={() => 'New question'}
        formDialogProps={{
          ...rest.formDialogProps,
          autoFocus: false,
          maxWidth: 'md',
          formProps: {
            ...rest.formDialogProps?.formProps,
            shouldUnregister: false,
            defaultValues: {
              ...rest.formDialogProps?.formProps?.defaultValues,
            },
          },
          formFetcherProps: {
            onFetch(item) {
              return {
                ...item,
                question: item.question?.[languageId] ?? '',
                answer: item.answer?.[languageId] ?? '',
                originalQuestion: item.question ?? {},
                originalAnswer: item.answer ?? {},
              };
            },
          },
          formSubmitterProps: {
            preSubmit(item) {
              return {
                question: {
                  ...item.originalQuestion,
                  [languageId]: item.question,
                },
                answer: {
                  ...item.originalAnswer,
                  [languageId]: item.answer,
                },
              };
            },
          },
        }}
      >
        <FormInput
          name="question"
          label="Question"
          required
          multiline
          rows={2}
        />
        <FormInput
          name="answer"
          label="Answer"
          sx={{ mt: 1 }}
          required
          multiline
          rows={5}
          helperText={
            <Box>
              You can use this{' '}
              <a href="https://stackedit.io/app#" target="_blank">
                markdown editor
              </a>
            </Box>
          }
        />
      </DataTableEx>
    );
  },
);
