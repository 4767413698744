import { LinkBehavior } from '@kirz/mui-admin';
import { Components, Theme } from '@mui/material';
import { LinkProps } from '@mui/material/Link';

import { colors } from './colors';
import type {} from '@mui/x-data-grid/themeAugmentation';

export const components: Components<Omit<Theme, 'components'>> | undefined = {
  MuiTooltip: {
    defaultProps: {
      enterDelay: 400,
    },
    styleOverrides: {
      popper: {
        pointerEvents: 'none',
      },
      tooltip: {
        pointerEvents: 'none',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: colors.neutral[500],
        color: '#FFFFFF',
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        paddingTop: '8px !important',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        '&.MuiInputBase-sizeSmall': {
          // paddingTop: '0 !important',
          // paddingBottom: '0 !important',
        },
      },
      input: {
        '&::placeholder': { opacity: 1, color: '#A0AEC0' },
        '&.MuiInputBase-inputSizeSmall': {
          // paddingTop: '10px !important',
          // paddingBottom: '10px !important',
        },
      },
    },
  },
  MuiGrid: {
    defaultProps: {
      spacing: 2,
    },
  },
  MuiGrid2: {
    defaultProps: {
      spacing: 2,
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      inputRoot: {
        '&.MuiInputBase-sizeSmall': {
          // paddingTop: 0,
          // paddingBottom: 0,
          // paddingLeft: 0,
        },
      },
      input: {
        // '&.MuiInputBase-inputSizeSmall': {
        //   paddingLeft: '14px !important',
        // },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      input: { fontWeight: 500 },
      notchedOutline: { borderColor: colors.border },
    },
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        borderColor: colors.border,
        borderStyle: 'solid',
        borderWidth: 1,
      },
    },
  },
  MuiPopover: {
    defaultProps: { elevation: 16 },
    styleOverrides: {
      paper: {
        borderColor: colors.border,
        borderStyle: 'solid',
        borderWidth: 1,
      },
    },
  },
  MuiSwitch: {
    defaultProps: { color: 'primary' },
    styleOverrides: {
      switchBase: { color: colors.neutral[700] },
      track: { backgroundColor: colors.neutral[500], opacity: 1 },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        padding: '15px 16px',
        borderBottom: '1px solid '.concat(colors.border),
      },
    },
  },
  MuiButton: {
    defaultProps: { disableElevation: !0 },
    styleOverrides: {
      root: { textTransform: 'none' },
      sizeSmall: { padding: '6px 16px' },
      sizeMedium: { padding: '8px 20px' },
      sizeLarge: { padding: '11px 24px' },
      textSizeSmall: { padding: '7px 12px' },
      textSizeMedium: { padding: '9px 16px' },
      textSizeLarge: { padding: '12px 16px' },
    },
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
      disableRipple: !0,
    },
  },
  MuiCardActions: {
    styleOverrides: { root: { padding: '16px 24px' } },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        padding: '32px 24px',
        '&:last-child': { paddingBottom: '32px' },
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: { variant: 'h6' },
      subheaderTypographyProps: { variant: 'body2' },
    },
    styleOverrides: { root: { padding: '32px 24px' } },
  },
  MuiCheckbox: { defaultProps: { color: 'primary' } },
  MuiCssBaseline: {
    styleOverrides: {
      '*': { boxSizing: 'border-box' },
      html: {
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
      },
      body: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        minHeight: '100%',
        width: '100%',
        overflowY: 'hidden',
      },
      '#__next': {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      },
      '#nprogress': { pointerEvents: 'none' },
      '#nprogress .bar': {
        backgroundColor: '#5048E5',
        height: 3,
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2e3,
      },
    },
  },
  MuiDrawer: {},
  MuiIconButton: {
    styleOverrides: {
      // root: { borderRadius: 8, padding: 8 },
      sizeSmall: { padding: 4 },
    },
  },
  MuiLinearProgress: {
    styleOverrides: { root: { borderRadius: 3, overflow: 'hidden' } },
  },
  MuiLink: {
    defaultProps: {
      underline: 'hover',
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        marginRight: '16px',
        '&.MuiListItemIcon-root': { minWidth: 'unset' },
      },
    },
  },
  MuiPaper: { styleOverrides: { root: { backgroundImage: 'none' } } },
  MuiRadio: { defaultProps: { color: 'primary' } },
  MuiTab: {
    styleOverrides: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.71,
        minWidth: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
        textTransform: 'none',
        '& + &': { marginLeft: 24 },
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      columnHeader: {
        userSelect: 'none',
        outline: 'none !important',
        paddingLeft: '8px',
        paddingRight: '0px',
      },
      cell: {
        outline: 'none !important',
        paddingLeft: '8px',
        paddingRight: '0px',
      },
      columnHeaders: {
        backgroundColor: colors.neutral[100],
        borderRadius: 0,
      },
      columnSeparator: {
        display: 'none',
      },
      root: {
        borderColor: '#e6e8f0',
      },
      footerContainer: {
        '& > div:first-of-type': {
          display: 'none',
        },
        '& .MuiTablePagination-displayedRows': {
          width: '120px',
        },
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        backgroundColor: colors.neutral[800],
        '.MuiTableCell-root': { color: colors.neutral[300] },
        borderBottom: 'none',
        '& .MuiTableCell-root': {
          borderBottom: 'none',
          fontSize: '12px',
          fontWeight: 600,
          lineHeight: 1,
          letterSpacing: 0.5,
          textTransform: 'uppercase',
        },
        '& .MuiTableCell-paddingCheckbox': {
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: 'rgba(18, 24, 40, 0.07)',
      },
    },
  },
};
