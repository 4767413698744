import {
  HasuraDataTableColumnDef,
  DataTableEx,
  DataTableExProps,
  DataTableExRef,
  FileInput,
  CheckboxInput,
  DateInput,
} from '@kirz/mui-admin';
import dayjs from 'dayjs';
import React, {
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';

export const AudioVariantsTable = forwardRef(
  (
    props: Partial<DataTableExProps> & {
      id: string;
      filterColumns?: (
        columns: HasuraDataTableColumnDef[],
      ) => HasuraDataTableColumnDef[];
    },
    ref: Ref<DataTableExRef>,
  ) => {
    const { filterColumns, ...rest } = props;

    const tableRef = useRef<DataTableExRef>(null);

    useImperativeHandle(ref, () => tableRef.current!, []);

    const columns = useMemo<HasuraDataTableColumnDef[]>(
      () =>
        (filterColumns || ((x: HasuraDataTableColumnDef[]) => x))([
          {
            field: 'fileId',
            headerName: 'Audio',
            type: 'file',
            sortable: false,
            flex: 1,
          },
          {
            field: 'duration',
            headerName: 'Duration',
            valueFormatter: ({ value }) => `${value} min.`,
          },
          {
            field: 'isPremium',
            headerName: 'Premium',
            type: 'boolean',
            sortable: false,
          },
          {
            field: 'newUntil',
            headerName: 'New',
            type: 'boolean',
            sortable: false,
            valueGetter({ value }) {
              return value && dayjs(value) > dayjs();
            },
          },
        ]),
      [filterColumns],
    );

    return (
      <DataTableEx
        {...rest}
        ref={tableRef}
        source="audioVariant"
        columns={columns}
        persistStateMode="query"
        sortBy={rest.sortBy ?? { field: 'duration', sort: 'asc' }}
        editable={{
          onEdit(row) {
            tableRef.current?.openFormDialog(row);
          },
        }}
        formTitle={(isNew) => (isNew ? 'New variant' : 'Edit variant')}
        formDialogProps={{
          ...rest.formDialogProps,
          formProps: {
            ...rest.formDialogProps?.formProps,
            defaultValues: {
              ...rest.formDialogProps?.formProps?.defaultValues,
              isNew: true,
              isPremium: false,
            },
          },
          formSubmitterProps: {
            preSubmit(item) {
              return {
                audioId:
                  rest.formDialogProps?.formProps?.defaultValues?.audioId,
                ...item,
              };
            },
          },
        }}
      >
        <FileInput
          name="fileId"
          label="Audio"
          required
          dropzoneProps={{
            accept: {
              'audio/wav': ['.wav'],
              'audio/aac': ['.aac'],
              'audio/mpeg': ['.mp3'],
            },
          }}
          md={12}
        />
        <DateInput name="newUntil" label="New until" sm={6} md={4} />
        <CheckboxInput name="isPremium" label="Premium" sm="auto" />
      </DataTableEx>
    );
  },
);
