import {
  DataTable,
  FormInput,
  DataTableRef,
  HasuraDataTableColumnDef,
  TablePageLayout,
  FormDialog,
} from '@kirz/mui-admin';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React, { useMemo, useRef, useState } from 'react';

export function Users() {
  const [isEditModalOpened, setIsEditModalOpened] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const tableRef = useRef<DataTableRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        minWidth: 40,
        width: 60,
      },
      {
        field: 'fullName',
        headerName: 'Name',
        flex: 1,
        type: 'name',
        activeDateKey: 'lastLoggedAt',
      },
      { field: 'email', headerName: 'E-mail', flex: 1 },
      { field: 'lastLoggedAt', headerName: 'Last active', type: 'date' },
    ],
    [],
  );

  return (
    <TablePageLayout
      title="Users"
      actionContent={
        <Button
          sx={{ ml: 'auto' }}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            setSelectedItem(null);
            setIsEditModalOpened(true);
          }}
        >
          Add new
        </Button>
      }
    >
      <DataTable
        id="users-table"
        ref={tableRef}
        mode="hasura"
        source="user"
        columns={columns}
        disableRemovedFilter
        editable={{
          onEdit: (row) => {
            setSelectedItem(row);
            setIsEditModalOpened(true);
          },
        }}
        sortBy={{ field: 'id', sort: 'desc' }}
        persistStateMode="query"
        selectProps={{
          filter: { role: { _eq: 'admin' } },
        }}
        searchFilter={{
          inputProps: {
            placeholder: 'Search by name or email',
          },
          filter: (search) => ({
            _or: search.flatMap((str) => [
              { fullName: { _ilike: `%${str}%` } },
              { email: { _ilike: `%${str}%` } },
            ]),
          }),
        }}
      />
      <FormDialog
        source="user"
        {...(selectedItem && { entityId: selectedItem.id })}
        open={isEditModalOpened}
        onClose={() => setIsEditModalOpened(false)}
        title={!selectedItem ? 'New user' : 'Edit user'}
        maxWidth="sm"
        onSubmit={() => {
          tableRef.current?.reload();
        }}
        formProps={{
          defaultValues: { cardDarkTheme: false },
        }}
        formSubmitterProps={{
          mode: 'rest',
          url: () =>
            !selectedItem
              ? { url: '/users', method: 'POST' }
              : { url: `/users/${selectedItem.id}`, method: 'PUT' },
          preSubmit(item) {
            return {
              ...item,
              role: 'admin',
            };
          },
        }}
        formFetcherProps={{
          selector: {
            password: null,
          },
        }}
      >
        <FormInput name="firstName" label="First name" md={6} />
        <FormInput name="lastName" label="Last name" md={6} />
        <FormInput name="email" label="E-mail" type="email" md={6} required />
        <FormInput
          name="password"
          label="Password"
          type="password"
          md={6}
          required={!selectedItem}
          autoComplete="new-password"
          placeholder={selectedItem && 'Enter to change'}
        />
      </FormDialog>
    </TablePageLayout>
  );
}
