import { Box, Typography } from '@mui/material';
import { AlertOutline } from 'mdi-material-ui';
import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { UserContext } from 'contexts/UserContext';

export function RouteGuard(props: { permission?: string | string[] }) {
  const { permission } = props;

  const { hasPermission } = useContext(UserContext);

  if (
    permission &&
    (typeof permission === 'string'
      ? !hasPermission(permission)
      : !permission.find((x) => hasPermission(x)))
  ) {
    return (
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          pb: 10,
        }}
      >
        <AlertOutline color="error" sx={{ fontSize: '100px', mb: 2 }} />
        <Typography variant="h5">Access denied</Typography>
      </Box>
    );
  }

  return <Outlet />;
}
