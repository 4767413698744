import {
  CheckboxInput,
  ConfigurationContext,
  DataTableExRef,
  FormPageLayout,
  Grid,
  LanguagesContext,
  TranslationInput,
} from '@kirz/mui-admin';
import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { YoutubeTv } from 'mdi-material-ui';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { VideoGroupItemsTable } from 'components/VideoGroupItemsTable';

export function VideoLane() {
  const routeParams = useParams() as Record<string, string>;
  const videoGroupId = parseInt(routeParams.id, 10);
  const tableRef = useRef<DataTableExRef>(null);
  const { hasura } = useContext(ConfigurationContext);
  const { defaultLanguageId } = useContext(LanguagesContext);
  const [sortValues, setSortValues] = useState<number[] | null>(null);
  const [refreshSortsTimestamp, setRefreshSortsTimestamp] = useState(
    new Date().valueOf(),
  );

  useEffect(() => {
    (async () => {
      const items = await hasura.request({
        type: 'query',
        source: 'videoGroupItem',
        selection: 'serialNumber',
        where: {
          videoGroupId: { _eq: videoGroupId },
        },
      });

      setSortValues(items.map((x: any) => x.serialNumber));
    })();
  }, [videoGroupId, refreshSortsTimestamp]);

  console.log(sortValues);

  return (
    <FormPageLayout
      source="videoGroup"
      breadcrumbs={[
        {
          text: 'Video Lanes',
          icon: YoutubeTv,
          href: '/video-lanes',
        },
        (item) => ({
          text: `#${item.id} ${item.name?.[defaultLanguageId]}`,
          copyOnClick: true,
        }),
      ]}
      breadcrumbsDeps={['name']}
      defaultRoute="/video-lanes"
    >
      <Grid container alignItems="flex-start">
        <TranslationInput name="name" label="Name" required />
        <CheckboxInput name="isActive" label="Active" sm="auto" />
      </Grid>
      <Box
        sx={{
          mt: 3,
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <VideoGroupItemsTable
          id="video-group-items-table"
          ref={tableRef}
          searchFilter={
            {
              slots: {
                beforeSearch: (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Videos</Typography>
                    <Button
                      startIcon={<Add />}
                      variant="outlined"
                      size="small"
                      sx={{ mx: 2, whiteSpace: 'pre' }}
                      onClick={() => {
                        tableRef.current?.openFormDialog();
                      }}
                    >
                      Add new
                    </Button>
                  </Box>
                ),
              },
            } as any
          }
          deletable={{
            onDeleted: (row) => {
              setSortValues(sortValues!.filter((x) => x !== row.serialNumber));
            },
          }}
          sortBy={{ field: 'serialNumber', sort: 'asc' }}
          sx={{
            mt: 1,
            minHeight: 400,
            mx: -2,
            borderColor: 'transparent',
          }}
          selectProps={{
            filter: {
              videoGroupId: { _eq: videoGroupId },
            },
          }}
          formDialogProps={{
            formProps: {
              defaultValues: {
                videoGroupId,
              },
            },
            onSubmit() {
              setRefreshSortsTimestamp(new Date().valueOf());
            },
          }}
          filterColumns={(columns) => [
            {
              field: 'serialNumber',
              type: 'sort',
              sortKey: 'serialNumber',
              rowsSortValues: sortValues,
              onSortChange: async (direction, row, targetRowSort) => {
                const source = 'VideoGroupItem';
                const { id } = row;

                await hasura.request({
                  type: 'custom',
                  query: `
                    mutation ChangeSort($where1: ${source}BoolExp!, $set1: ${source}SetInput!, $where2: ${source}BoolExp!, $set2: ${source}SetInput!) {
                      result2: update${source}(where: $where2, _set: $set2) {
                        affected_rows
                      }
                      result1: update${source}(where: $where1, _set: $set1) {
                        affected_rows
                      }
                    }
                  `,
                  variables: {
                    where1: { id: { _eq: id } },
                    set1: { serialNumber: targetRowSort },
                    where2: {
                      serialNumber: { _eq: targetRowSort },
                      videoGroupId: { _eq: videoGroupId },
                    },
                    set2: { serialNumber: row.serialNumber },
                  },
                });

                tableRef.current?.reload();
              },
            },
            ...columns
              .filter((x) => !['Category'].includes(x.headerName!))
              .map((x) => ({ ...x, sortable: false })),
          ]}
        />
      </Box>
    </FormPageLayout>
  );
}
